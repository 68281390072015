<template>
  <div class="registration wrapper auth-layout">
    <div class="registration-form">
      <div class="aml-logo">
        <img src="/images/aml-logo.svg" alt="aml" />
      </div>
      <div class="form-wrapper">
        <h1 class="form-title">Реєстрація</h1>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-input-container">
              <ValidationProvider
                vid="usreou_code"
                name="ЄДРПОУ"
                rules="required|digits:8"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  v-model="form.usreou_code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ЄДРПОУ вашої компанії"
                  autocomplete="off"
                  name="company"
                  class="form__input form__input_primary"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>

            <div class="form-input-container">
              <ValidationProvider
                vid="phone"
                name="номер телефону (+380440000000)"
                rules="required|length:13"
                v-slot="{ errors }"
              >
                <input
                  v-model="form.phone"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                  placeholder="Номер телефону"
                  type="tel"
                  name="tel"
                  class="form__input form__input_primary"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container">
              <ValidationProvider
                vid="email"
                name="адреса електронної пошти"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                  placeholder="E-mail"
                  name="email"
                  class="form__input form__input_primary"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </div>
            <div class="form-input-container form-input-container__password  ">
              <ValidationProvider
                vid="password"
                name="пароль"
                rules="required|password"
                v-slot="{ errors }"
              >
                <input
                  :type="showPassword ? passwordFildType : 'password'"
                  v-model="form.password"
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                  placeholder="••••••••••••••"
                  class="form__input form__input_primary form__input-password"
                />
                <b-form-invalid-feedback :state="errors.length === 0">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>

              <div
                class="password-icon"
                type="password"
                @click="switchVisibility"
                :class="{
                  'form-input-container__password_open': showPassword,
                  'form-input-container__password_close': !showPassword
                }"
              ></div>
            </div>
            <button :disabled="submitting" type="submit" class="form__button">
              Зареєструватися
            </button>
            <div class="form__link">
              Вже маєте обліковий запис?
              <b-link :to="{ name: 'Login' }">Увійти</b-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </div>
</template>

<script>
import api from "../../api/api";

export default {
  name: "Register",
  data: () => ({
    showPassword: false,
    passwordFildType: "password",
    submitting: false,
    form: {
      usreou_code: "",
      phone: "",
      email: "",
      password: ""
    }
  }),
  methods: {
    async onSubmit() {
      this.switchSubmitting();
      await api
        .registerUser(this.form)
        .then(response => {
          this.$store.dispatch("auth/saveToken", {
            token: response.data.success
          });
          this.$store.dispatch("auth/getMe").then(() => {
            this.switchSubmitting();
            this.$router.push({
              redirect: (window.location.href = window.location.origin)
            });
          });
        })
        .catch(({ response }) => {
          this.switchSubmitting();
          if (response.status !== 406) {
            return;
          }

          if (!response.data.description) {
            return;
          }

          this.$refs.form.setErrors(response.data.description);
        });
    },
    switchSubmitting() {
      this.submitting = !this.submitting;
    },
    switchVisibility() {
      this.passwordFildType =
        this.passwordFildType === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    }
  }
};
</script>
<style>
body,
p {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
}
* {
  transition: all 0.2s ease;
  box-sizing: border-box;
  color: inherit;
  text-decoration: none;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}
</style>

<style scoped>
.form-control:focus {
  border-color: #e4e7ea;
  box-shadow: none;
}
.form-control.is-invalid:focus {
  border-color: #f86c6b;
  box-shadow: none;
}
</style>
